import { DestroyRef, Injectable, inject } from '@angular/core';
import { filter, map, Subject } from 'rxjs';
import { WebsocketService } from './websocket.service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DialogService } from '@mca/shared/feature-dialog';
import { environmentToken } from '@mca/shared/util';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private websocketService = inject(WebsocketService);
  private dialogService = inject(DialogService);
  private destroyRef = inject(DestroyRef);
  private env = inject(environmentToken);

  private closeDialog = new Subject<boolean>();
  closeDialog$ = this.closeDialog.asObservable();
  channel = new BroadcastChannel('command');

  watchMessages() {
    if (!this.env.websocketUrl) {
      return;
    }
    this.websocketService
      .listen()
      .pipe(
        filter(message => message.messageType === 'notification'),
        map(data => data.body.lastMessage.message),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(message => {
        this.dialogService
          .confirm({
            title: 'Notification',
            message,
            confirmLabel: 'OK',
            cancelLabel: '',
          })
          .subscribe(() => {
            this.dialogService.closeModal();
            this.channel.postMessage('Notification closed');
            this.closeDialog.next(true);
          });
      });
  }
}
