import { HttpErrorResponse } from '@angular/common/http';
import { DestroyRef, Injectable, effect, inject, signal } from '@angular/core';
import { ApiService, environmentToken } from '@mca/shared/util';
import { minutesToMilliseconds } from 'date-fns';
import { MessageService } from 'primeng/api';
import { catchError, EMPTY, filter, interval, of, switchMap, throwError } from 'rxjs';
import { LiveMessageList, LiveMessageData, LiveMessagePost } from '../../entities/live-messages';
import { httpSystemLiveMessages, httpSystemNewLiveMessage } from '../../infrastructure/system-http-endpoints';
import { WebsocketService } from './websocket.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class LiveMessagesService {
  private apiService = inject(ApiService);
  private messageService = inject(MessageService);
  private websocketService = inject(WebsocketService);
  private destroyRef = inject(DestroyRef);
  env = inject(environmentToken);

  messageData = signal({} as LiveMessageData);

  get lastId() {
    return this.messageData()?.lastId ?? 0;
  }

  constructor() {
    effect(() => {
      if (this.messageData().lastMessage?.message) {
        this.messageService.add({ severity: 'info', summary: 'New message', detail: this.messageData().lastMessage?.message });
      }
    });
    if (this.env.websocketUrl) {
      this.listenWebsocket();
    } else {
      this.startPolling();
    }
  }

  sendLiveMessage(data: LiveMessagePost) {
    return this.apiService.post(httpSystemLiveMessages(), data);
  }

  getLiveMessages() {
    return this.apiService.get<LiveMessageList>(httpSystemLiveMessages());
  }

  getNewLiveMessage() {
    return this.apiService.get<LiveMessageData>(httpSystemNewLiveMessage(this.lastId)).pipe(
      catchError((e: HttpErrorResponse) => {
        if (e.status === 304) {
          return of(this.messageData());
        }
        return throwError(() => e);
      }),
    );
  }

  // call in constructor to switch to http polling if websocket does not work
  private startPolling() {
    const delay = minutesToMilliseconds(1);
    interval(delay)
      .pipe(
        switchMap(() => this.getNewLiveMessage().pipe(catchError(() => EMPTY))),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(newMessage => this.messageData.set(newMessage));
  }

  private listenWebsocket() {
    this.websocketService
      .listen()
      .pipe(
        filter(message => message.messageType === 'chat'),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(message => this.messageData.set(message.body));
  }
}
