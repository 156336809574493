import { DestroyRef, Injectable, Injector, inject } from '@angular/core';
import { filter, map } from 'rxjs';
import { WebsocketService } from './websocket.service';
import { environmentToken } from '@mca/shared/util';
import { BECommand, BECommandAction, BECommandData } from '../../entities/be-command';
import { WebsocketMessage } from '../../entities/websocket';
import { BECommandLogoutService } from './be-command-logout.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type CommandConstructor = new (...args: any[]) => BECommand;

const commandMap: Record<BECommandAction, CommandConstructor> = {
  logout: BECommandLogoutService,
};

@Injectable({
  providedIn: 'root',
})
export class BECommandService {
  private websocketService = inject(WebsocketService);
  private env = inject(environmentToken);
  private destroyRef = inject(DestroyRef);
  private injector = inject(Injector);

  watchMessages() {
    if (!this.env.websocketUrl) {
      return;
    }
    this.websocketService
      .listen()
      .pipe(
        filter(
          (message): message is WebsocketMessage<BECommandData> => message.messageType === 'command' && message.body.action in commandMap,
        ),
        map(data => data.body),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(command => {
        this.injector.get(commandMap[command.action]).execute(command);
      });
  }
}
